import { writable } from "svelte/store";

function useState() {
	const { subscribe, set, update } = writable(false);
	return {
		subscribe,
		updateState: () => { update(value => !value) },
		reset: () => set(false),
		force: (boolean) => set(boolean)
	}
}

/* Kitsune Auto-Scroll Actions */

export let setKitsuneScroll = useState()

export function targetClicked() {
	setKitsuneScroll.force(true)
}
export function resetTarget() {
	setKitsuneScroll.force(false);
}

/* Auto Hide Debugger */

export let hideDebugPanel = useState()

export function togglePanel() {
	hideDebugPanel.updateState()
}

/* Toggle Enhancement Zoom */

export let setEnhancement = useState()

export function toggleZoom() {
	setEnhancement.updateState();
}

/* Toggle Menu on Close */

export let mobileMenuStatus = useState();
export let outsideActivity = useState();

export let openMenu = () => {
	mobileMenuStatus.force(true)
}

export let closeMenu = () => {
	mobileMenuStatus.force(false)
}

export let updateActivity = async () => {
	outsideActivity.force(true)
	setTimeout(() => {
		outsideActivity.force(false)
		mobileMenuStatus.force(false)
	}, 200)
}

/* Capture Load State of HomePage Image */

export let setLoaderReady = useState();
/* Track Email Submission Status */

export let emailSuccess = useState();
export let emailFailure = useState();


