export const contractAddress = {
    rinkeby: {
        ronin:          "0xD346209a81004c0aD794C1da4B94703A7EbFE3f8",
        roninViewer:    "0x7905De1a9e5229F8dbb878Fc8566CA84a1f43712",
        kitsune:        "0xaC0A973Ccf570593036964E4F0f52c5050E7407A",
        kitsuneViewer:  "0x0B2c406168E4b174E7736401738B6F05cd501707",
        roninBento:     "0x07EC424a61589672521b5AEA28Cd3EEC76D53573",
    },
    ropsten: {
        // ronin:          "",
        // roninViewer:    "",
        // kitsune:        "",
        // kitsuneViewer:  "",
        // roninBento:     "",
    },
    ganache: {
			ronin:          "0xF0BC942c8b02bd9Fd6367cABbccC40d2eFa5B9A3",
			roninViewer:    "0x48AC5A1C267b23725d0388f1000E409f986557F8",
			kitsune:        "0xfdA8e46eB1F411D5A9be0c4bAE55d13D8db1fB60",
			kitsuneViewer:  "0x59fbB5c6D3fC50d675B2e86Fff0a2124f5e10ba7",
			roninBento:     "0x2b4E21634788eB5Cd88dF512a0ce2ED9065d9CDC",
    },
    mainnet:{
        ronin:              "0x2127fe7ffce4380459cced92f2d4793f3af094a4",
        roninViewer:        "0xe3bc843ed809eac4b63c0d8564993f5f3340cea2",
        kitsune:            "0xFC2d7c0FD2D353923D47cCD2409f8Ee19e86fd29",
        kitsuneViewer:      "0xbE210A933bb83157B1c778A33DBbE998C39f09B3",
        roninBento:         "0x77A9D70c82c0fBb689Ef74E430CCac984Bec26C8"
    }
}
