export const target_network = {
    name: "homestead",
    chainId: 1
};
// export const target_network = {
//     name: "unknown",
//     chainId: 1337
// };
// export const target_network = {
//     name: "ropsten",
//     chainId: 3
// };
// export const target_network = {
//     name: "rinkeby",
//     chainId: 4
// };

// export const target_network = {
// 	name: "ganache",
// 	chainId: 5777,
// };
// export const target_network = {
//     name: "ganache",
//     chainId: 1337
// };
