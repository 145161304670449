import { writable } from 'svelte/store';
import {state, data, Contract, onTransaction, offTransaction} from '../stores/contracts'
import {CEREMONY_OFFSET} from '../stores/manager'

export let ID = {};
export let currentID = writable(ID);

export let _hash = {};
export let currentHash = writable(_hash);

export let _isGold = false;
export let isGold = writable(_isGold);

export let _isGoldLoaded = false;
export let isGoldLoaded = writable(_isGoldLoaded);

function useState() {
	const { subscribe, set, update } = writable(false)
	return {
		subscribe,
		updateState: () => { update(value => !value) },
		reset: () => set(false),
		force: (boolean) => set(boolean)
	}
}

/* <-- ASCENSION STATES --> */

export let ASCENDING = useState();
export let ASCENDED = useState();
export let SELECTING = useState();
export let SELECTED = useState();

export let ERROR = useState();
export let LOADING = useState();

export let ASCENSION_FAILURE = useState();
export let ASCENSION_PENDING = useState();
export let ASCENSION_SUCCESS = useState();

/* <-- MINT STATES --> */

export let MINT_ERROR = useState();
export let MINT_FAILURE = useState();
export let MINT_PENDING = useState();
export let MINT_INITIAL_STATE = useState();
export let MINT_SUCCESS = useState();


let txCount = 0;


function killAscensionCallbacks(txNum){
	if(ascensionCallbacks[txNum]){
		offTransaction("confirm",	ascensionCallbacks[txNum].confirm);
		offTransaction("fail",	ascensionCallbacks[txNum].fail);
		offTransaction("cancel",	ascensionCallbacks[txNum].cancel);
	}
}
function addAscensionCallbacks(txNum,callbacks){
	ascensionCallbacks[txNum] = callbacks;
}
const ascensionCallbacks = {
	// "0":
	// 	{
	// 		confirm: 	id,
	// 		fail:		id,
	// 		cancel: 	id,
	//	}
}


function killMintCallbacks(txNum){
	if(mintCallbacks[txNum]){
		offTransaction("submit",	mintCallbacks[txNum].submit);
		offTransaction("confirm",	mintCallbacks[txNum].confirm);
		offTransaction("fail",	mintCallbacks[txNum].fail);
		offTransaction("cancel",	mintCallbacks[txNum].cancel);
	}
}
function addMintCallbacks(txNum,callbacks){
	mintCallbacks[txNum] = callbacks;
}
const mintCallbacks = {
	// "0":
	// 	{
	// 		submit: 	id,
	// 		confirm: 	id,
	// 		fail:		id,
	// 		cancel: 	id,
	//	}
}



export const launchAscension = () => {
	txCount++;
	const txNum = txCount;


	LOADING.updateState();
	ASCENSION_PENDING.updateState()
	Contract.ronin.ceremony(ID);

	// onTx Callbacks Here


	let callbacks = {
		//confirm:
		//fail:
		//cancel:
	}

	callbacks.confirm =  onTransaction("confirm", (func,params,hash) => {
		if(func !== "ceremony") return;

		if (ID > 10000) {
			ID = Number(ID)
		} else {
			ID = Number(ID) + CEREMONY_OFFSET;
		}
		completedAscension()

		killAscensionCallbacks(txNum);
	})

	callbacks.fail =  onTransaction("fail", (func,params, hash) => {
		if(func !== "ceremony") return;

		onFailure();

		killAscensionCallbacks(txNum);
	})

	callbacks.cancel = onTransaction("cancel", (func,params) => {
		if(func !== "ceremony") return;

		cancelAscension();

		killAscensionCallbacks(txNum);
	})

	addAscensionCallbacks(txNum,callbacks);
}

export const launchMint = () => {
	txCount++;
	const txNum = txCount;

	let callbacks = {
		//submit:
		//confirm:
		//fail:
		//cancel:
	}


	callbacks.request = onTransaction("request", (func,params) => {
		if(!(["mint","mintBento"].includes(func))) return;

		initializeMint()

	})

	callbacks.submit = onTransaction("submit", (func,params, hash) => {
		if(!(["mint","mintBento"].includes(func))) return;

		_hash = hash;
		currentHash.set(_hash.toString());
	})
	callbacks.confirm = onTransaction("confirm", (func,params, hash) => {
		if(!(["mint","mintBento"].includes(func))) return;

		completedMint()

		killMintCallbacks(txNum);
	})
	callbacks.fail = onTransaction("fail", (func,params, hash) => {
		if(!(["mint","mintBento"].includes(func))) return;

		declareMintError()

		killMintCallbacks(txNum);
	})
	callbacks.cancel = onTransaction("cancel", (func,params) => {
		if(!(["mint","mintBento"].includes(func))) return;

		setTimeout(() => {resetMintState(); killMintCallbacks(txNum);}, 1000)
		// resetMintState()
	})

	addMintCallbacks(txNum,callbacks);
}

export const cancelAscension = () => {
	currentID.set(ID)
	ASCENDING.reset()
	ASCENDED.reset()
	SELECTING.reset()
	SELECTED.reset()
	ASCENSION_PENDING.reset()
	ASCENSION_SUCCESS.reset()
	LOADING.reset()
}

export const onFailure = () => {
	ASCENSION_FAILURE.updateState();
	ERROR.updateState();
}

export const preAscension = () => {
	SELECTING.updateState();
	ASCENDING.updateState();
}

export const mountedForAscension = () => {
	SELECTED.force(true);
	ASCENDING.force(true);
	SELECTING.reset();
}

export const postAscension = () => {
	ASCENDED.updateState()
	ASCENDING.reset();
	SELECTING.reset();
}

export const completedAscension = () => {
	ASCENDED.force(true);
	ASCENDING.force(false);
	ASCENSION_PENDING.force(true);
	ASCENSION_SUCCESS.force(true);
	LOADING.force(true);
	SELECTED.force(false);
	SELECTING.force(true);
}

export const initializeMint = () => {
	MINT_PENDING.force(true)
	MINT_ERROR.force(false)
}

export const declareMintError = () => {
	MINT_ERROR.force(true)
	MINT_PENDING.reset();
}
export const completedMint = () => {
	MINT_SUCCESS.force(true)
	MINT_PENDING.reset()
}

export const onMintFailure = () => {
	MINT_FAILURE.force(true)
}

export const resetMintState = () => {
	MINT_SUCCESS.reset()
	MINT_FAILURE.reset()
	MINT_ERROR.reset()
	MINT_PENDING.reset()
}

export const identifyRonin = (roninID) => {
	let regex = /\d+/g;
	ID = roninID.match(regex).join('');
	if (ID < 9999) {
		currentID.set(ID);
	}
}