import { ethers } from "ethers";
import { get, writable } from 'svelte/store';

import {target_network} from "../targetNetwork";
import {target_mainnet, unlocked} from "../wallet";

const ALCHEMY = "kx1G_PqaRTAAnAiVFecRipBRpOV_K6f9";

export let provider_alchemy;

let network = target_network.name;
if(network === "homestead") network = "mainnet";


let initialised = false;
export async function init(){
    if(initialised) return;
    initialised = true;

}


let unlockCallback = ()=>{
    for(let u = 0; u < unlockCallbacks.length; u++){
        unlockCallbacks[u]();
    }
};
let unlockCallbacks = [];
export function onUnlocked(callback){
    unlockCallbacks.push(callback);
}

let lockCallback = ()=>{
    for(let l = 0; l < lockCallbacks.length; l++){
        lockCallbacks[l]();
    }
};
let lockCallbacks = [];
export function onLocked(callback){
    lockCallbacks.push(callback);
}



let readyFunc = ()=>{};
export let isReady = false;
export function onReady(func){
    readyFunc = func;
}
async function triggerReady(){
    // console.log("alchemy: Call onReady");
    isReady = true;
    // await readyFunc();
    await unlockCallback();;
}

init();

function get_provider_infura(){
    return provider_alchemy;
}


export async function unlock(){
    if(network === "ganache"){
        console.log("GANACHE!")
        provider_alchemy = await new ethers.providers.JsonRpcProvider("http://localhost:8545");
        await triggerReady()
        return;
    }

    setTimeout(async ()=>{
        if(get(unlocked)) return;

        provider_alchemy = await new ethers.providers.AlchemyProvider (network,ALCHEMY);
        await triggerReady();
    },1);
}
export async function lock(){
    await lockCallback();
}