import {target_network} from "./targetNetwork";
let network_name = target_network.name;
if(network_name === "homestead") network_name = "mainnet";


import Web3Modal from "web3modal";

import WalletConnectProvider from "@walletconnect/web3-provider";

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            chainId: target_network.chainId,
            infuraId: "54e18451e68c4ecd857484bea45a1f27" // required

        }
    }

};




// const provider = new WalletConnectProvider({
//     infuraId: "54e18451e68c4ecd857484bea45a1f27",
// });

// console.error("11111");
// console.log(provider);
//
// // Subscribe to accounts change
// provider.on("accountsChanged", (accounts) => {
//     console.log(accounts);
// });
// // Subscribe to chainId change
//
// provider.on("chainChanged", (chainId) => {
//     console.log(chainId);
// });
//
// provider.on("disconnect", (code,reason) => {
//     console.log(code,reason);
// });





const web3Modal = new Web3Modal({
    // network: "mainnet", // optional
    // network: "rinkeby", // optional
    // network: "ropsten", // optional
    network: network_name,
    cacheProvider: true, // optional
    providerOptions, // required
});


// killProvider()

export async function getProvider(){
    // await provider.enable();
    // console.log(provider)
    // return provider;
    // return provider.enable();


    // console.log(web3Modal)

    return await web3Modal.connect();
}

export async function killProvider(){

    web3Modal.resetState();
    await web3Modal.clearCachedProvider();
}