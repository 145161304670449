import { ethers } from "ethers";

import {contractAddress} from "./config/contractAddresses";

import {unlock as unlock_alchemy, lock as lock_alchemy } from "./web3/alchemy/wallet";

import {getNetworkName,readNumberArg,cleanAddress,isMe,isZeroAddress,Contract, onEvent, offEvent, state as state_connected, onConnect, offConnect, Data,data as data_connected,me, onTransaction, setRequestKeyParams, pending,requested} from "./contracts";
import {Contract as Contract_alchemy, Data as Data_alchemy,  state as state_alchemy, data as data_alchemy, onConnect as onConnect_alchemy, onEvent as onEvent_alchemy, offEvent as offEvent_alchemy} from "./web3/alchemy/contracts";
import {writable, derived, get} from "svelte/store";

export const PRICE_MINT_ETHER = "0.0888";
export const PRICE_MINT = ethers.utils.parseEther(PRICE_MINT_ETHER);
export const CEREMONY_OFFSET = 10000;

export const RONIN_ONLY = false;

//Loader funcs
async function getContractState(useAlchemy,roninOnly = false){
    console.log("  get contract state")
    if(roninOnly){
        const properties = [
            "roninSupply",
            "roninUnclaimed",
            "roninPaused",
        ];
        if(useAlchemy){
            await Data_alchemy.loadDatas(properties, async ()=>{
                const values = await Contract_alchemy.roninViewer.contractState();

                return {
                    roninSupply:        BigInt(values.roninCount),
                    roninUnclaimed:     BigInt(values.reserved),
                    roninPaused:        Boolean(values.paused),
                }
            });
        }else{
            await Data.loadDatas(properties, async ()=>{
                const values = await Contract.roninViewer.contractState();

                return {
                    roninSupply:        BigInt(values.roninCount),
                    roninUnclaimed:     BigInt(values.reserved),
                    roninPaused:        Boolean(values.paused),
                }
            });
        }

    }else{
        const properties = [
            "roninSupply",
            "roninUnclaimed",
            "roninPaused",
            "kitsuneSupply",
            "kitsuneStarted",
        ];
        if(useAlchemy){
            await Data_alchemy.loadDatas(properties, async ()=>{
                const values = await Contract_alchemy.kitsuneViewer.contractState();

                return {
                    roninSupply:        BigInt(values._roninSupply),
                    roninUnclaimed:     BigInt(values._roninUnclaimed),
                    roninPaused:        Boolean(values._roninPaused),
                    kitsuneSupply:      BigInt(values._kitsuneSupply),
                    kitsuneStarted:     Boolean(values._kitsuneStarted),
                }
            });
        }else{
            await Data.loadDatas(properties, async ()=>{
                const values = await Contract.kitsuneViewer.contractState();

                return {
                    roninSupply:        BigInt(values._roninSupply),
                    roninUnclaimed:     BigInt(values._roninUnclaimed),
                    roninPaused:        Boolean(values._roninPaused),
                    kitsuneSupply:      BigInt(values._kitsuneSupply),
                    kitsuneStarted:     Boolean(values._kitsuneStarted),
                }
            });
        }
    }
}
async function getMyState(roninOnly = false){
    console.log("  get my state");
    if(roninOnly){
        const properties = [
            "myRoninCount",
            "myRoninReserveCount",
        ]

        await Data.loadDatas(properties, async ()=>{
            const values = await Contract.roninViewer.myState([]);

            return {
                myRoninCount:           BigInt(values.myBalance),
                myRoninReserveCount:    BigInt(values.myReserved),
            }
        });
    }else{
        const properties = [
            "myRoninCount",
            "myRoninReserveCount",
            "myKitsuneCount",
        ]

        await Data.loadDatas(properties, async ()=>{
            const values = await Contract.kitsuneViewer.myState();

            return {
                myRoninCount:           BigInt(values._myRoninCount),
                myRoninReserveCount:    BigInt(values._myRoninReserveCount),
                myKitsuneCount:         BigInt(values._myKitsuneCount),
            }
        });
    }



}
async function getRonins(useAlchemy){
    console.log("  get ronins...")

    if(useAlchemy){
        await Data_alchemy.loadData("ronins",async()=>{
            //TODO: may not like i
            let _ronins = [];
            let _limit = 8888;
            for(let i = 0; i < 8888; i += _limit){
                let chunk  = await Contract_alchemy.roninViewer.ronins(i,_limit);
                if(chunk.length === 0) break;
                _ronins.push(...chunk);
            }
            for(let i = 0; i < _ronins.length; i++){
                _ronins[i] = parseInt(_ronins[i]);
            }
            return _ronins;
        });
    }else{
        await Data.loadData("ronins",async()=>{
            //TODO: may not like i
            let _ronins = [];
            let _limit = 8888;
            for(let i = 0; i < 8888; i += _limit){
                let chunk  = await Contract.roninViewer.ronins(i,_limit);
                if(chunk.length === 0) break;
                _ronins.push(...chunk);
            }
            for(let i = 0; i < _ronins.length; i++){
                _ronins[i] = parseInt(_ronins[i]);
            }
            return _ronins;
        });
    }
}
async function getKitsunes(useAlchemy){

    console.log("  get kitsunes...")

    if(useAlchemy){
        await Data_alchemy.loadData("kitsunes",async()=>{
            //TODO: may not like i
            let _kitsunes = [];
            let _limit = 8888;
            for(let i = 1; i <= 8888; i += _limit){
                let chunk  = await Contract_alchemy.kitsuneViewer.kitsunes(i,_limit);
                if(chunk.length === 0) break;
                _kitsunes.push(...chunk);
            }
            for(let i = 0; i < _kitsunes.length; i++){
                _kitsunes[i] = parseInt(_kitsunes[i]);
            }
            return _kitsunes;
        });
    }else{
        await Data.loadData("kitsunes",async()=>{
            //TODO: may not like i
            let _kitsunes = [];
            let _limit = 8888;
            for(let i = 1; i <= 8888; i += _limit){
                let chunk  = await Contract.kitsuneViewer.kitsunes(i,_limit);
                if(chunk.length === 0) break;
                _kitsunes.push(...chunk);
            }
            for(let i = 0; i < _kitsunes.length; i++){
                _kitsunes[i] = parseInt(_kitsunes[i]);
            }
            return _kitsunes;
        });
    }
}
async function getUnclaimedKitsunes(useAlchemy){

    console.log("  get unclaimed kitsunes...")

    if(useAlchemy){
        await Data_alchemy.loadData("unclaimedKitsunes",async()=>{
            //TODO: may not like i
            let _kitsunes = [];
            let _limit = 8888;
            for(let i = 0; i < 8888; i += _limit){
                let chunk = await Contract_alchemy.kitsuneViewer.unclaimed(i,_limit);
                if(chunk.length === 0) break;
                _kitsunes.push(...chunk);
            }
            for(let i = 0; i < _kitsunes.length; i++){
                _kitsunes[i] = parseInt(_kitsunes[i]);
            }
            return _kitsunes;
        });
    }else{
        await Data.loadData("unclaimedKitsunes",async()=>{
            //TODO: may not like i
            let _kitsunes = [];
            let _limit = 8888;
            for(let i = 0; i < 8888; i += _limit){
                let chunk  = await Contract.kitsuneViewer.unclaimed(i,_limit);
                if(chunk.length === 0) break;
                _kitsunes.push(...chunk);
            }
            for(let i = 0; i < _kitsunes.length; i++){
                _kitsunes[i] = parseInt(_kitsunes[i]);
            }
            return _kitsunes;
        });
    }
}
async function getMyRonins(){
    console.log("  get my ronins...");
    await Data.loadData("myRonins",async()=>{
        let _ronins = [];
        let _limit = 8888;
        for(let i = 0; i < 8888; i += _limit){
            let chunk  = await Contract.roninViewer.myRonins(i,_limit);
            if(chunk.length === 0) break;
            _ronins.push(...chunk);
        }
        for(let i = 0; i < _ronins.length; i++){
            _ronins[i] = parseInt(_ronins[i]);
        }
        return _ronins;
    });
}
async function getMyKitsunes(){
    console.log("  get my kitsunes...");
    await Data.loadData("myKitsunes",async()=>{
        let _kitsunes = [];
        let _limit = 8888;
        for(let i = 1; i <= 8888; i += _limit){
            let chunk  = await Contract.kitsuneViewer.myKitsunes(i,_limit);
            // console.log("->",chunk.length)
            if(chunk.length === 0) break;
            _kitsunes.push(...chunk);
        }
        let _kitsunesClean = [];
        for(let i = 0; i < _kitsunes.length; i++){
            _kitsunes[i] = parseInt(_kitsunes[i]);
            if(_kitsunes[i] !== 0){
                _kitsunesClean.push(_kitsunes[i]);
            }
        }
        // console.log(">>>",_kitsunes);
        return _kitsunesClean;
    });
}

export async function claimable(_tokenId){
    if(get(useAlchemy)){
        return await Contract_alchemy.kitsune.claimable(_tokenId);
    }else{
        return await Contract.kitsune.claimable(_tokenId);
    }
}


//Meta funcs
export async function mintRonin(_count){
    const value = BigInt(PRICE_MINT) * BigInt(_count);
    await Contract.ronin.mint(_count,value.toString());
}
export async function claimRonin(_count){
    await Contract.ronin.claim(me,_count);
}
export async function claimKitsunes(_tokenIds){
    await Contract.kitsune.claimMultiple(_tokenIds);
}
export async function mintBento(_count){
    const value = BigInt(PRICE_MINT) * BigInt(_count);
    await Contract.roninBento.mintBento(_count,value);
}

setRequestKeyParams("ceremony",["tokenId"]);

function getContractAddress(_contract){
    return cleanAddress(contractAddress[getNetworkName()][_contract]);
}

const eventIds ={
    alchemy: {
        Transfer: [],
        Claim: [],
        Pause: [],
        Start: [],
        Reserve: [],
        ClaimKitsune: [],
    },
    connect: {
        Transfer: [],
        Claim: [],
        Pause: [],
        Start: [],
        Reserve: [],
        ClaimKitsune: [],
    }
}
function registerEvents(useAlchemy){
    console.log("  register events...");

    if(useAlchemy){
        let id = onEvent_alchemy("Transfer",(params,eventObj)=>{
            const _tokenId = readNumberArg(params._tokenId);
            const _to = cleanAddress(params._to);
            const _from = cleanAddress(params._from);

            const contractAddress = cleanAddress(eventObj.address); //TODO: this may not be correct property of eventObj
            const isRonin = contractAddress === getContractAddress("ronin");
            const isKitsune = contractAddress === getContractAddress("kitsune");

            if(isZeroAddress(_from)){
                //Mint

                if(isRonin){
                    Data_alchemy.safePush("ronins",_tokenId);
                    Data_alchemy.safeIncrement("roninSupply");
                }
                if(isKitsune){
                    Data_alchemy.safePush("kitsunes",_tokenId);
                    Data_alchemy.safeIncrement("kitsuneSupply");


                    const unclaimed = Data_alchemy.getData("unclaimedKitsunes");

                    if(unclaimed.includes(_tokenId)){
                        Data_alchemy.safeRemove("unclaimedKitsunes",_tokenId);
                    }
                    if(unclaimed.includes(_tokenId + CEREMONY_OFFSET)){
                        Data_alchemy.safeRemove("unclaimedKitsunes",_tokenId  + CEREMONY_OFFSET);
                    }
                }
            }
            if(isZeroAddress(_to)){
                //Burn

                if(isRonin){
                    Data_alchemy.safeRemove("ronins",_tokenId);
                    Data_alchemy.safeDecrement("roninSupply");
                }
                if(isKitsune){
                    Data_alchemy.safeRemove("kitsunes",_tokenId);
                    Data_alchemy.safeDecrement("kitsuneSupply");
                }
            }
        });
        eventIds.alchemy.Transfer.push(id);


        id = onEvent_alchemy("Claim",(params,eventObj)=>{
            const tokenId = readNumberArg(params.tokenId);
            const reservist = cleanAddress(params.reservist);

            Data_alchemy.safeDecrement("roninUnclaimed");
        });
        eventIds.alchemy.Claim.push(id);


        id = onEvent_alchemy("Pause",(params,eventObj)=>{
            const _pause        = Boolean(params._pause);
            const _unpausable   = Boolean(params._unpausable);
            const _startTime    = readNumberArg(params._startTime);
            const _pauseTime    = readNumberArg(params._pauseTime);

            Data_alchemy.safeUpdate("roninPaused",_pause);
        });
        eventIds.alchemy.Pause.push(id);



        id = onEvent_alchemy("Start",(params,eventObj)=>{
            const _started        = Boolean(params._started);

            Data_alchemy.safeUpdate("kitsuneStarted",_started);
        });
        eventIds.alchemy.Start.push(id);



        id = onEvent("Reserve",(params,eventObj)=>{
            const tokenId   = readNumberArg(params.tokenId);
            const reservist = cleanAddress(params.reservist);

            Data_alchemy.safeIncrement("roninUnclaimed");
        });
        eventIds.alchemy.Reserve.push(id);


        id = onEvent("ClaimKitsune",(params,eventObj)=>{
            const roninId = readNumberArg(params.roninId);
            const kitsuneId = readNumberArg(params.kitsuneId);
            const claimer = cleanAddress(params.claimer);

            // Data_alchemy.safeIncrement("kitsuneSupply");

        });
        eventIds.alchemy.ClaimKitsune.push(id);

    }else{
        let id = onEvent("Transfer",(params,eventObj)=>{
            const _tokenId = readNumberArg(params._tokenId);
            const _to = cleanAddress(params._to);
            const _from = cleanAddress(params._from);

            const contractAddress = cleanAddress(eventObj.address); //TODO: this may not be correct property of eventObj
            const isRonin = contractAddress === getContractAddress("ronin");
            const isKitsune = contractAddress === getContractAddress("kitsune");

            if(isZeroAddress(_from)){
                //Mint

                if(isRonin){
                    Data.safePush("ronins",_tokenId);
                    Data.safeIncrement("roninSupply");
                }
                if(isKitsune){
                    Data.safePush("kitsunes",_tokenId);
                    Data.safeIncrement("kitsuneSupply");


                    const unclaimed = Data.getData("unclaimedKitsunes");

                    if(unclaimed.includes(_tokenId)){
                        Data.safeRemove("unclaimedKitsunes",_tokenId);
                    }
                    if(unclaimed.includes(_tokenId + CEREMONY_OFFSET)){
                        Data.safeRemove("unclaimedKitsunes",_tokenId  + CEREMONY_OFFSET);
                    }
                }
            }
            if(isZeroAddress(_to)){
                //Burn

                if(isRonin){
                    Data.safeRemove("ronins",_tokenId);
                    Data.safeDecrement("roninSupply");
                }
                if(isKitsune){
                    Data.safeRemove("kitsunes",_tokenId);
                    Data.safeDecrement("kitsuneSupply");
                }
            }

            if(isMe(_to)){
                //Receive

                if(isRonin){
                    Data.safePush("myRonins",_tokenId);
                    Data.safeIncrement("myRoninCount");
                }
                if(isKitsune){
                    Data.safePush("myKitsunes",_tokenId);
                    Data.safeIncrement("myKitsuneCount");
                }
            }
            if(isMe(_from)){
                //Send

                if(isRonin){
                    Data.safeRemove("myRonins",_tokenId);
                    Data.safeDecrement("myRoninCount");
                }
                if(isKitsune){
                    Data.safeRemove("myKitsunes",_tokenId);
                    Data.safeDecrement("myKitsuneCount");
                }
            }
        });
        eventIds.connect.Transfer.push(id);


        id = onEvent("Claim",(params,eventObj)=>{
            const tokenId = readNumberArg(params.tokenId);
            const reservist = cleanAddress(params.reservist);

            Data.safeDecrement("roninUnclaimed");

            if(isMe(reservist)){
                Data.safeDecrement("myRoninReserveCount");
            }
        });
        eventIds.connect.Claim.push(id);


        id = onEvent("Pause",(params,eventObj)=>{
            const _pause        = Boolean(params._pause);
            const _unpausable   = Boolean(params._unpausable);
            const _startTime    = readNumberArg(params._startTime);
            const _pauseTime    = readNumberArg(params._pauseTime);

            Data.safeUpdate("roninPaused",_pause);
        });
        eventIds.connect.Pause.push(id);



        id = onEvent("Start",(params,eventObj)=>{
            const _started        = Boolean(params._started);

            Data.safeUpdate("kitsuneStarted",_started);
        });
        eventIds.connect.Start.push(id);


        id = onEvent("Reserve",(params,eventObj)=>{
            const tokenId   = readNumberArg(params.tokenId);
            const reservist = cleanAddress(params.reservist);

            Data.safeIncrement("roninUnclaimed");
            if(isMe(reservist)){
                Data.safeIncrement("myRoninReserveCount");
            }
        });
        eventIds.connect.Reserve.push(id);


        id = onEvent("ClaimKitsune",(params,eventObj)=>{

            // console.log("??>> claim kitsune")
            // console.log(">>>");

            const roninId = readNumberArg(params.roninId);
            const kitsuneId = readNumberArg(params.kitsuneId);
            const claimer = cleanAddress(params.claimer);

            // console.log("??>> 2")
            // console.log("|",roninId)
            // console.log("|",kitsuneId)
            // console.log("|",claimer)


            // Data.safeIncrement("kitsuneSupply");

            // Data.safePush("kitsunes",kitsuneId);

            if(isMe(claimer)){


                Data.safeRemove("unclaimedKitsunes",roninId);

                // Data.safeIncrement("myKitsuneCount");


                // Data.safePush("myKitsunes",kitsuneId);

                // console.log("claim finished")

                claimingKitsuneCallbacks.finish(kitsuneId);
            }
        });
        eventIds.connect.ClaimKitsune.push(id);

    }
}
function deregisterEvents(useAlchemy){
    console.warn("deregister events");
    console.log('alchemy:',useAlchemy)
    if(useAlchemy){
        for(let event in  eventIds.alchemy){
            eventIds.alchemy[event].map(eventId =>{
                offEvent_alchemy(event,eventId);
            });
        }
    }else{
        for(let event in  eventIds.connect){
            eventIds.connect[event].map(eventId =>{
                offEvent(event,eventId);
            });

        }
    }
}


onConnect_alchemy("connect", async() =>{
    console.log("Connect to alchemy");

    await getContractState(true,RONIN_ONLY);

    await getRonins(true);

    if(!RONIN_ONLY){
        await getKitsunes(true);
        await getUnclaimedKitsunes(true);
    }

    await registerEvents(true);

    console.log("connected");
});

onConnect("connect",async ()=>{

    console.log("Connect to wallet")

    await getContractState(false,RONIN_ONLY);
    await getMyState(RONIN_ONLY);


    await getRonins(false);

    if(!RONIN_ONLY){
        await getKitsunes(false);
        await getUnclaimedKitsunes(false);
    }

    await getMyRonins();

    if(!RONIN_ONLY) {
        await getMyKitsunes();
    }

    //tx hooks
    registerKitsuneTxHooks();

    await registerEvents(false);

    console.log("connected");

    //Kill Alchemy
    await lock_alchemy();
    deregisterEvents(true);
});

onConnect("disconnect",async ()=>{
    console.log("Disconnect to wallet")

    //kill tx hooks
    deregisterKitsuneTxHooks()

    //Kill self events
    deregisterEvents(false);

    //Revive alchemy
    registerEvents(true);
    await unlock_alchemy();

});



const txIds = {
    request: [],
    submit:  [],
    confirm: [],
    fail:    [],
    cancel:  [],
}
function registerKitsuneTxHooks(){
    let id = onTransaction("request",(func,params,hash)=>{
        if(func !== "claimMultiple") return;

        claimingKitsuneCallbacks.start();
    })
    txIds.request.push(id);

    id = onTransaction("cancel",(func,params,hash)=>{
        if(func !== "claimMultiple") return;

        claimingKitsuneCallbacks.cancel();
    })
    txIds.cancel.push(id);

    id = onTransaction("fail",(func,params,hash)=>{
        if(func !== "claimMultiple") return;

        claimingKitsuneCallbacks.fail();
    })
    txIds.fail.push(id);
}
function deregisterKitsuneTxHooks(){
    for(let stage in  txIds){
        txIds[stage].map(txId =>{
            offEvent(stage,txId);
        });

    }
}

const claimingKitsuneCallbacks = {
    start: async ()=>{},
    cancel: async ()=>{},
    fail: async ()=>{},
    finish: async ()=>{},
}

export function onStartClaimingKitsune(callback){
    claimingKitsuneCallbacks.start = callback;
}
export function onCancelClaimingKitsune(callback){
    claimingKitsuneCallbacks.cancel = callback;
}
export function onFailClaimingKitsune(callback){
    claimingKitsuneCallbacks.fail = callback;
}
export function onFinishClaimingKitsune(callback){
    claimingKitsuneCallbacks.finish = callback;
}


export let useAlchemy = derived(state_connected, $state_connected => $state_connected==="initial");

export let state = derived([state_connected,state_alchemy, useAlchemy], ($values, set)=> {set($values[2]?$values[1]:$values[0])});

export let data = derived([data_connected,data_alchemy, useAlchemy], ($values, set)=> {set($values[2]?$values[1]:$values[0])});

export let transacting = derived([pending, requested], ($values,set)=>{
   set(recursiveBoth($values[0],$values[1]));
});

function recursiveBoth(a,b){
    if(typeof a === "boolean" || typeof b === "boolean"){
        return a || b;
    }else{
        let c = {};
        for(let j in a){
            c[j] = recursiveBoth(a[j],b[j]);
        }
        for(let j in b){
            c[j] = recursiveBoth(a[j],b[j]);
        }
        return c;
    }
}


setTimeout(()=>{
    if(get(useAlchemy)){
        unlock_alchemy();
    }
},1000);
