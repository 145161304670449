export const contractAbi = {
    ronin:  [
        "event Start()",
        "event Pause(bool _pause,bool _unpausable,uint _startTime,uint _pauseTime)",
        "event Reserve(address indexed reservist, uint indexed tokenId)",
        "event Claim(address indexed reservist, uint indexed tokenId)",
        "event Ceremony(uint indexed tokenId)",
        "event Transfer(address indexed _from, address indexed _to, uint256 indexed _tokenId)",

        "function phase() view returns(uint)",
        "function catMinted(address minter) view returns(bool)",

        "function catMint(bytes32[] merkleProof) payable",
        "function reserve(uint16 _count) payable",
        "function claim(address reservist, uint _count) nonpayable",

        "function mint(uint _count) payable",
        "function ceremony(uint tokenId) nonpayable",

        "function totalSupply() view returns(uint)"
    ],
    roninViewer:  [
        "function canReserve(address reservist) view returns(bool)",
        "function cooldown(address reservist) view returns(uint24)",
        "function canCatMint(bytes32[] proof, address catOwner) view returns(bool)",
        "function countdown() view returns(bool counting, uint time)",
        "function contractState() view returns(uint roninCount, uint reserved, uint phase, bool counting, uint time, bool paused, uint blockNumber)",
        "function myState(bytes32[] merkleProof) view returns(uint myBalance, uint myReserved, uint cooldown, bool _canCatMint, bool _canReserve, uint blockNumber)",
        "function ronins(uint start_index, uint limit) view returns(uint[])",
        "function myRonins(uint start_index, uint limit) view returns(uint[])",
    ],
    kitsune:  [
        "event Start(bool _started)",
        // "event Claim(uint indexed roninId)",
        "event ClaimKitsune(uint indexed roninId, uint indexed kitsuneId, address claimer)",

        "event Transfer(address indexed _from, address indexed _to, uint256 indexed _tokenId)",
        "function ownerOf(uint256 _tokenId) view returns(address)",
        "function balanceOf(address _tokenId) view returns(uint)",

        "function claimKitsune(uint _tokenId)",
        "function claimMultiple(uint[] _tokenIds)",

        "function claimable(uint _tokenId) view returns(bool)",

        "function totalSupply() view returns(uint)",


    ],
    kitsuneViewer:  [
        "function unclaimed(uint start_index, uint limit) view returns(uint[])",
        "function myKitsunes(uint startId, uint limit) view returns(uint[])",
        "function contractState() view returns(uint _roninSupply, uint _roninUnclaimed, bool _roninPaused, uint _kitsuneSupply, bool _kitsuneStarted)",

        "function myState() view returns(uint _myRoninCount, uint _myRoninReserveCount, uint _myKitsuneCount)",

        "function kitsunes(uint startId, uint limit)  view returns(uint[])",
    ],
    roninBento: [
        "function mintBento(uint _count) payable",
    ]
}