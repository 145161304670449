import { ethers } from "ethers";

import {contractAbi} from "./config/contractAbi";
import {contractAddress} from "./config/contractAddresses";
import {contractData} from "./config/contractData";

import {get, set, readable, writable} from 'svelte/store';
import {target_network} from "./web3/targetNetwork";
import {
    provider,
    signer,
    address as myAddress,
    unlock,
    unlocked,
    onWalletChange,
    correct_network,
    wallet_injected, onUnlocked, onLocked,
    target_mainnet,
    check_unlock
} from "./web3/wallet.js";

export function getNetworkName(){
    if(target_network.name === "homestead") return "mainnet";
    return target_network.name;
}

function fnv32a() {
    let str = JSON.stringify(arguments);
    let hval = 0x811c9dc5;
    for ( let i = 0; i < str.length; ++i )
    {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    return hval >>> 0;
}
export function id(){
    return (fnv32a(arguments,Date.now())).toString();
}

export function Loadable(type,initial){
    const variable = {
        type: type,
        initial: true,
        loading: false,
        loaded:  false,
        error:   false,
        state: "initial", //initial, loading, loaded, error
    }
    if(typeof initial !== "undefined") {
        variable.value = initial;
    }else {
        switch(type){
            case "boolean":
                variable.value = false;
                break;
            case "string":
                variable.value = "";
                break;
            case "number":
                variable.value = 0;
                break;
            case "bigInt":
                variable.value = 0n;
                break;
            case "array":
                variable.value = [];
                break;
            case "object":
                variable.value = {};
                break;
        }
    }
    return variable;
}

export function readObject(variable,prop){
    return _data[variable].value[prop];
}
export function hasProperty(variable,prop){
    return typeof _data[variable].value[prop] !== "undefined";
}

export let _data = {}
for(let d in contractData){
    _data[d] = Loadable(...contractData[d]);
}
export let data = writable(_data);
let stateId = 0;


export const Data = {
    loadData:           $loadData,
    loadDatas:          $loadDatas,
    setData:            $setData,
    getData:            $getData,
    loading:            $loading,
    loaded:             $loaded,
    error:              $error,
    initial:            $initial,

    isLoaded:           $isLoaded,
    safeIncrement:      $safeIncrement,
    safeDecrement:      $safeDecrement,
    safeUpdate:         $safeUpdate,

    safeSetObjProp:     $safeSetObjProp,
    safeDeleteObjProp:  $safeDeleteObjProp,
    safePush:           $safePush,
    safeRemove:         $safeRemove,
}

async function $loadData(property,loadFunc){
    let _stateId = stateId;
    $loading(property,_stateId);
    try{
        const value = await loadFunc();
        $setData(property,value,_stateId);
        $loaded(property,_stateId);
    }catch(e){
        $error(property,_stateId);
    }
}
async function $loadDatas(properties,loadFunc){
    let _stateId = stateId;
    properties.map(property => {$loading(property,_stateId)});

    try{
        const values = await loadFunc();
        properties.map(property => {
            $setData(property,values[property],_stateId,false);
        });

        properties.map(property => {
            $loaded(property,_stateId,false);
        });

        data.set(_data);
    }catch(e){
        console.log("failed to load datas")
        console.log(e);
    }
}
function $setData(property,value, _stateId = stateId, updateWritable = true){
    if(_stateId !== stateId) return;

    if(_data[property].type !== "object"){
        _data[property].value = value;

    }else{
        _data[property].value = value;
    }

    if(updateWritable){
        data.set(_data);
    }else{
    }

}
function $getData(property){
    return _data[property].value;
}
function $loading(property, _stateId = stateId){
    if(_stateId !== stateId) return;
    _data[property].state = 'loading';

    _data[property].initial = false;
    _data[property].loading = true;
    _data[property].loaded =  false;
    _data[property].error =   false;

    data.set(_data);
}
function $loaded(property, _stateId = stateId, updateWritable = true){
    if(_stateId !== stateId) return;
    _data[property].state = 'loaded';

    _data[property].initial = false;
    _data[property].loading = false;
    _data[property].loaded =  true;
    _data[property].error =   false;
    if(updateWritable){
        data.set(_data);
    }

}
function $error(property, _stateId = stateId){
    if(_stateId !== stateId) return;
    _data[property].state = 'error';

    _data[property].initial = false;
    _data[property].loading = false;
    _data[property].loaded =  false;
    _data[property].error =   true;

    data.set(_data);
}
function $initial(property, _stateId = stateId){
    if(_stateId !== stateId) return;
    _data[property].state = 'initial';

    _data[property].initial = true;
    _data[property].loading = false;
    _data[property].loaded =  false;
    _data[property].error =   false;

    data.set(_data);
}
function $isLoaded(property){
    return _data[property].loaded;
}

function $safeIncrement(property){
    if($isLoaded(property)){
        let _value = $getData(property);
        $setData(property,parseInt(_value) + 1);
    }
}
function $safeDecrement(property){
    if($isLoaded(property)){
        let _value = $getData(property);
        $setData(property,parseInt(_value) - 1);
    }
}
function $safeUpdate(property,value){
    if($isLoaded(property)){
        $setData(property,value);
    }
}

function $safeSetObjProp(property,objProp,value){
    if($isLoaded(property)){
        let _value = $getData(property);
        _value[objProp] = value;
        $setData(property,_value);
    }
}
function $safeDeleteObjProp(property,objProp){
    if($isLoaded(property)){
        let _value = $getData(property);
        delete _value[objProp];
        $setData(property,_value);
    }
}


function $safePush(property,value){
    if($isLoaded(property)){
        let _array = $getData(property);
        _array.push(value);
        $setData(property,_array);
    }else{
        console.log("PUSH: NOT LOADED");
    }
}
function $safeRemove(property,value){
    if($isLoaded(property)){
        let _array = $getData(property);
        let _index = _array.indexOf(value);

        if(_index !== -1){
            _array.splice(_array.indexOf(value),1);
            $setData(property,_array);
        }else{
            console.log("REMOVE: not found")
        }
    }else{
        console.log("REMOVE: NOT LOADED");
    }
}



export const ZERO =    "0x0000000000000000000000000000000000000000";

let contract = {}
let contractWithSigner = {}
export let me;


const BN = ethers.BigNumber;

export let state = writable('initial'); //initial, loading, loaded, network, error


export function cleanAddress(address){
    return String(address).toLowerCase();
}
export function isMe(address){
    return(cleanAddress(address) === me);
}
export function isZeroAddress(address){
    return cleanAddress(address) === cleanAddress(ZERO);
}

const transactions = {
    // hash:{
    //     status: "pending",
    //     func: "purchase", //purchase
    //     params: {},
    // }
}

export let _pending = {
    // inhabit:        {},     // cityId
    // reinforce:      {},     // tokenId
    // evacuate:       {},     // tokenId
    // confirmHit:     {},     // tokenId
    // winnerWithdraw: false,
}
export let pending = writable(_pending);
export let _requested = {
    // inhabit:        {},     // cityId
    // reinforce:      {},     // tokenId
    // evacuate:       {},     // tokenId
    // confirmHit:     {},     // tokenId
    // winnerWithdraw: false,
}
export let requested = writable(_requested);

const parsed_events = {};

const _events = {};
const callbacks = {
    event: {
        // Transfer:       [],
        // Inhabit:        [],
        // Reinforce:      [],
        // Impact:         [],
    },

    transaction: {
        request: [],
        submit:  [],
        confirm: [],
        fail:    [],
        cancel:  [],
    },
    connect: {
        connect:    [],
        disconnect: [],
        reset:      [],
    }
}

export function onEvent(event,callback){
    callback.id = id(event,callback,Date.now());
    if(!callbacks.event[event]){
        console.log("NO EVENT:",event);
        return;
    }
    callbacks.event[event].push(callback);
    return callback.id;
}
export function offEvent(event,id){
    for(let i = callbacks.event[event].length - 1; i >= 0; i--){
        if(callbacks.event[event][i].id === id){
            callbacks.event[event].splice(i,1);
        }
    }
}

export function onTransaction(stage,callback){
    callback.id = id(stage,callback);
    callbacks.transaction[stage].push(callback);
    return callback.id;
}
export function offTransaction(stage,id){
    for(let i = callbacks.transaction[stage].length - 1; i >= 0; i--){
        if(callbacks.transaction[stage][i].id === id){
            callbacks.transaction[stage].splice(i,1);
        }
    }
}

function Event(event,params,eventObj){
    if(!params) params = [];

    for(let i = 0; i < callbacks.event[event].length; i++){
        callbacks.event[event][i](params,eventObj);
    }
}
function Transaction(stage,func,params,hash = null){
    if(!params) params = [];
    for(let i = 0; i < callbacks.transaction[stage].length; i++){
        callbacks.transaction[stage][i](func,params,hash);
    }
}

function _updatePending(func,params,value){
    _updateRequestState(_pending,func,params,value);
    pending.set(_pending);
}
function _updateRequested(func,params,value){
    _updateRequestState(_requested,func,params,value);
    requested.set(_requested);
}

function _updateRequestState(_stateObject,func,params,value){
    const requestKeyParams = getRequestKeyParams(func);
    if(!requestKeyParams){
        _stateObject[func] = value;
    }else{
        const key = getRequestKey(requestKeyParams,params);
        _stateObject[func][key] = value;
    }
}
function getRequestKey(keyParams,params){
    const keyComponents = [];
    keyParams.map(param => {
        keyComponents.push(params[param]);
    });
    return keyComponents.join("-");
}

const requestKeys = {}
export function setRequestKeyParams(func,keyParams){
    _pending[func] = {};
    _requested[func] = {};
    requestKeys[func] = keyParams;

    pending.set(_pending);
    requested.set(_requested);
}
export function getRequestKeyParams(func){
    if(requestKeys[func]){
        return requestKeys[func];
    }else{
        return false;
    }
}


//Transaction hooks
function confirm_transaction(hash){

    if(!transactions[hash]) return;
    const func = transactions[hash].func;
    const params = transactions[hash].params;

    _updatePending(func,params,false);

    delete transactions[hash];

    Transaction("confirm",func,params,hash);
}
function cancel_transaction(func,params){
    //Note: no hash
    _updateRequested(func,params,false);
    Transaction("cancel",func,params);
}
function request_transaction(func,params){
    //Note: no hash
    _updateRequested(func,params,true);

    Transaction("request",func,params);
}
function register_transaction(tx,func,params){
    transactions[tx.hash] = {
        status: "pending",
        func: func,
        params: params,
    }

    _updatePending(func,params,true);
    _updateRequested(func,params,false);

    Transaction("submit",func,params,tx.hash);

    await_failure(tx.hash);
}
const await_failure = async(hash) => {
    const failure = (await provider.waitForTransaction( hash )).status === 0;
    if(failure){
        fail_transaction(hash);
    }
}
function fail_transaction(hash){
    if(!transactions[hash]) return;

    const func = transactions[hash].func;
    const params = transactions[hash].params;

    _updatePending(func,params,false);

    delete transactions[hash];

    Transaction("fail",func,params,hash);
}

export function alert_error(e){
    switch(e.code){
        case 4001:
            ///cancel
            break;
        case "INSUFFICIENT_FUNDS":
            alert("You don't have enough ETH to make this transaction");
            break;
        default:
            if(e.message.includes("execution reverted: difficulty")){
                alert("Error: Your mining result is no longer valid and your transaction will fail.");
            }else{
                console.log(e);
                if(e.data && e.data.message){
                    alert("Metamask error: "+e.data.message);
                }else{
                    alert("Metamask error: "+e.message);
                }

            }
            break;

    }
}
async function _transact(contract,func,params,value){

    request_transaction(func,params.arg_obj);
    let tx;
    let args = [];
    let args_estimates = [];
    if(params.args){
        args = [...params.args]
        args_estimates = [...params.args]
    }
    const overrides = {}

    if(value){
        overrides.value = value;
    }
    try{
        args_estimates.push(overrides);

        // let gasEstimate =  await contractWithSigner[contract].estimateGas[func](...args_estimates);
        // overrides.gasLimit  = Math.round(gasEstimate * 1.1).toString();

        args.push(overrides);
        tx =  await contractWithSigner[contract][func](...args);
        register_transaction(tx,func,params.arg_obj);
        await tx.wait();
    }catch(e){
        console.log(e);
        cancel_transaction(func,params.arg_obj);
        alert_error(e);
        return false;
    }
    confirm_transaction(tx.hash);
}
async function _read(contract,func,params){
    let args = [];
    if(params) {
        args = [...params]
    }
    return await contractWithSigner[contract][func](...args);
}
function parse_(event,callThrough){
    _events[event] = callThrough;
}
function parse_event(event){
    if(typeof event === "undefined") {
        console.log('undefined event');
        return;
    }

    const index = event.logIndex+event.transactionHash.substr(1,12);
    if(parsed_events[index]) {
        return;
    }
    parsed_events[index] = true;

    if(_events[event.event]){
        _events[event.event](event.args,event);
    }

    Event(event.event,event.args,event);

}


export function readNumberArg(arg){
    if(typeof arg._hex !== "undefined"){
        return parseInt(arg._hex);
    }else{
        return parseInt(arg);
    }
}
export function readBigIntArg(arg){
    return BigInt(readNumberArg(arg));
}

// function parse_events(){
//
// }


export function parseDefinition(definition){
    const segments = definition.split(" ");
    const Def = {

    }
    switch(segments[0]){
        case "event":
            Def.type = "event";
            let eventName;
            if (segments[1].includes("(")){
                eventName = segments[1].substr(0,segments[1].indexOf("("));
            }else{
                eventName = segments[1];
            }
            Def.name = eventName;
            break
        case "function":
            Def.type = "function";
            let functionName;
            if (segments[1].includes("(")){
                functionName = segments[1].substr(0,segments[1].indexOf("("));
            }else{
                functionName = segments[1];
            }
            Def.name = functionName;

            if(
                definition.includes(" view ") ||
                definition.includes(")view")
            ){
                Def.mutability = "read";
            }else{
                Def.mutability = "write";

                Def.payable = (
                    definition.includes(" payable ") ||
                    definition.includes(")payable")
                );
            }

            const params = ((definition.split("(")[1]).split(")")[0]).trim();
            const paramList = params.split(",");

            Def.params = [];
            paramList.map(param => {
                param = param.trim();
                const p = param.split(" ");
                Def.params.push(p[p.length - 1]);
            })
            break;
    }
    return Def;
}

export const Contract = {

};
const ContractEvents = {}

for(let contractName in contractAbi){
    const C = {};
    // Contract[contractName] = {
    //
    // };
    const events = [];
    contractAbi[contractName].map(definition => {
        const D = parseDefinition(definition);
        switch (D.type){
            case "event":
                events.push(D.name);
                callbacks.event[D.name] = [];
                break;
            case "function":
                let func;
                if(D.mutability === "read"){
                    func = async (...funcArgs) => {
                        let args = [];
                        if(funcArgs.length <= D.params.length){
                            for(let i = 0; i < funcArgs.length; i++){
                                args.push(funcArgs[i]);
                            }
                        }else{
                            for(let i = 0; i < D.params.length; i++){
                                args.push(funcArgs[i]);
                            }
                        }
                        return await _read(contractName,D.name,args);
                    }
                }else{
                    func = async (...funcArgs) => {
                        let args = [];
                        let arg_obj = {};
                        if(funcArgs.length <= D.params.length){
                            for(let i = 0; i < funcArgs.length; i++){
                                args.push(funcArgs[i]);
                                arg_obj[D.params[i]] = funcArgs[i];
                            }
                        }else{
                            for(let i = 0; i < D.params.length; i++){
                                args.push(funcArgs[i]);
                                arg_obj[D.params[i]] = funcArgs[i];
                            }
                        }
                        const params = {
                            args,
                            arg_obj
                        }

                        if(D.params.length < funcArgs.length){
                            const value = parseInt(funcArgs[funcArgs.length - 1]);
                            await _transact(contractName,D.name,params,(value).toString());
                        }else{
                            await _transact(contractName,D.name,params);
                        }
                    }
                }
                C[D.name] = func;
                break;
        }

    });
    // registerEvents(contractName,events);
    Contract[contractName] = C;
    ContractEvents[contractName] = events;

}




export function onConnect(transition,callback){
    callback.id = id(callback);;
    callbacks.connect[transition].push(callback);
    return callback.id;
}
export function offConnect(transition,id){
    for(let i = callbacks.connect[transition].length - 1; i >= 0; i--){
        if(callbacks.connect[transition][i].id === id){
            callbacks.connect[transition].splice(i,1);
        }
    }
}
async function doConnectCallbacks(transition){
    console.log("do connect callbacks:",transition);
    for(let i = 0; i < callbacks.connect[transition].length; i++){
        await callbacks.connect[transition][i]();
    }
}

export async function connect(){
    state.set('loading');

    console.log("CONNECT INJECTED");
    console.log("NETWORK:",getNetworkName());


    for(let c in contractAddress[getNetworkName()]){
        contract[c] = new ethers.Contract(contractAddress[getNetworkName()][c],contractAbi[c],provider);
        contractWithSigner[c] = contract[c].connect(signer);
    }

    me = cleanAddress(get(myAddress));

    for(let contractName in ContractEvents){
        await registerEvents(contractName,ContractEvents[contractName]);
    }



    await doConnectCallbacks("connect");

    state.set('loaded');
}

async function reset(){
    console.log("injected reset")

    //TODO: reset _data,data
    //  interrupt loads
    stateId = Date.now();
    for(let property in _data){
        const type = _data[property].type;
        delete _data[property];
        _data[property] = Loadable(type);
    }
    data.set(_data);

    for(let func in _pending){
        if(requestKeys[func]){
            _pending[func]   = {};
            _requested[func] = {};
        }else{
            _pending[func]   = false;
            _requested[func] = false;
        }
    }
    pending.set(_pending);
    requested.set(_requested);

    for(let tx in transactions){
        delete transactions[tx];
    }

    for(let evt in parsed_events){
        delete parsed_events[evt];
    }

    await doConnectCallbacks("reset");

}

function registerEvents(contractName,event_names){
    // console.log(contractName)
    //
    // console.log(">>",contractName,contractWithSigner[contractName]);
    // console.log(contractWithSigner);

    for(let e = 0; e < event_names.length; e++){
        const event_name = event_names[e];
        contractWithSigner[contractName].on( event_name , (a0,a1,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20)=>{
            let event;
            //default arguments variable isnt workin for some reason. this will do
            let args = [a0,a1,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20];

            for(let a = 0; a < args.length; a++) {
                if (typeof args[a] === "object"
                    && args[a].event === event_name) {
                    event = args[a];
                    break;
                }
            }
            if(!event) return;
            parse_event(event);
        } );
    }
}

async function unlockCallback(){
    // console.error("contract: unlock callback")

    if(!get(correct_network)){
        state.set("network");
    }else{
        await connect();
    }

    onWalletChange(async (correctNetwork)=>{
        await reset();
        if(!correctNetwork){
            state.set("network");
        } else{
            await connect();
        }
    });
}
async function lockCallback(){
    state.set("initial");
    await reset();
    // window.location.reload();

    await doConnectCallbacks("disconnect");
}



async function hardReset(){
    await reset();
    if(!get(correct_network)){
        state.set("network");
    }else{
        await connect();
    }
}
let initialised = false;
export async function init() {
    if(initialised) return;
    initialised = true;

    if(typeof window.ethereum === "undefined"){
        console.log('not injected')
        // return;
    }else{
        console.log('injected')

    }


    onUnlocked(unlockCallback);
    onLocked(lockCallback);


    // await unlock();
    await check_unlock();
}


init();