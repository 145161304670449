import {get, set, readable, writable} from 'svelte/store';
import {ethers} from "ethers";


import {target_network} from "../targetNetwork";
import {correct_network, provider, unlocked} from "../wallet";
import {provider_alchemy, onReady, isReady} from "./wallet";

import {onLocked, onUnlocked} from "./wallet";

import {contractAbi}        from "../../config/contractAbi";
import {contractAddress}    from "../../config/contractAddresses";
import {contractData}       from "../../config/contractData";
import {
    Loadable,
    id,
    getNetworkName,
    ZERO,
    cleanAddress,
    isZeroAddress,
    readNumberArg,
    alert_error,
    parseDefinition,
    _pending, _requested, pending, requested
} from "../../contracts";



export let _data = {}
for(let d in contractData){
    _data[d] = Loadable(...contractData[d]);
}
export let data = writable(_data);
let stateId = 0;

export const Data = {
    loadData:           $loadData,
    loadDatas:          $loadDatas,
    setData:            $setData,
    getData:            $getData,
    loading:            $loading,
    loaded:             $loaded,
    error:              $error,
    initial:            $initial,

    isLoaded:           $isLoaded,
    safeIncrement:      $safeIncrement,
    safeDecrement:      $safeDecrement,
    safeUpdate:         $safeUpdate,

    safeSetObjProp:     $safeSetObjProp,
    safeDeleteObjProp:  $safeDeleteObjProp,
    safePush:           $safePush,
    safeRemove:         $safeRemove,
}



async function $loadData(property,loadFunc){
    let _stateId = stateId;
    $loading(property,_stateId);
    try{
        const value = await loadFunc();
        $setData(property,value,_stateId);
        $loaded(property,_stateId);
    }catch(e){
        $error(property,_stateId);
    }
}
async function $loadDatas(properties,loadFunc){
    let _stateId = stateId;
    properties.map(property => {$loading(property,_stateId)});

    try{
        const values = await loadFunc();
        properties.map(property => {
            $setData(property,values[property],_stateId,false);
        });

        properties.map(property => {
            $loaded(property,_stateId,false);
        });
        data.set(_data);
    }catch(e){
        console.log("failed to load datas")
        console.log(e);
    }
}
function $setData(property,value, _stateId = stateId, updateWritable = true){
    if(_stateId !== stateId) return;

    if(_data[property].type !== "object"){
        _data[property].value = value;

    }else{
        _data[property].value = value;
    }

    if(updateWritable){
        data.set(_data);
    }else{
    }

}
export function $getData(property){
    return _data[property].value;
}
function $loading(property, _stateId = stateId){
    if(_stateId !== stateId) return;
    _data[property].state = 'loading';

    _data[property].initial = false;
    _data[property].loading = true;
    _data[property].loaded =  false;
    _data[property].error =   false;

    data.set(_data);
}
function $loaded(property, _stateId = stateId){
    if(_stateId !== stateId) return;
    _data[property].state = 'loaded';

    _data[property].initial = false;
    _data[property].loading = false;
    _data[property].loaded =  true;
    _data[property].error =   false;

    data.set(_data);
}
function $error(property, _stateId = stateId){
    if(_stateId !== stateId) return;
    _data[property].state = 'error';

    _data[property].initial = false;
    _data[property].loading = false;
    _data[property].loaded =  false;
    _data[property].error =   true;

    data.set(_data);
}
function $initial(property, _stateId = stateId){
    if(_stateId !== stateId) return;
    _data[property].state = 'initial';

    _data[property].initial = true;
    _data[property].loading = false;
    _data[property].loaded =  false;
    _data[property].error =   false;

    data.set(_data);
}
function $isLoaded(property){
    return _data[property].loaded;
}

function $safeIncrement(property){
    if($isLoaded(property)){
        let _value = $getData(property);
        $setData(property,parseInt(_value) + 1);
    }
}
function $safeDecrement(property){
    if($isLoaded(property)){
        let _value = $getData(property);
        $setData(property,parseInt(_value) - 1);
    }
}
function $safeUpdate(property,value){
    if($isLoaded(property)){
        $setData(property,value);
    }
}

function $safeSetObjProp(property,objProp,value){
    if($isLoaded(property)){
        let _value = $getData(property);
        _value[objProp] = value;
        $setData(property,_value);
    }
}
function $safeDeleteObjProp(property,objProp){
    if($isLoaded(property)){
        let _value = $getData(property);
        delete _value[objProp];
        $setData(property,_value);
    }
}



function $safePush(property,value){
    if($isLoaded(property)){
        let _array = $getData(property);
        _array.push(value);
        $setData(property,_array);
    }else{
        console.log("PUSH: NOT LOADED");
    }
}
function $safeRemove(property,value){
    if($isLoaded(property)){
        let _array = $getData(property);
        let _index = _array.indexOf(value);

        if(_index !== -1){
            _array.splice(_array.indexOf(value),1);
            $setData(property,_array);
        }else{
            console.log("REMOVE: not found")
        }
    }else{
        console.log("REMOVE: NOT LOADED");
    }
}

let contract = {}
const BN = ethers.BigNumber;

export let state = writable('initial'); //initial, loading, loaded, network, error

const parsed_events = {};

const _events = {};
const callbacks = {
    event: {
        // Transfer:       [],
        // Inhabit:        [],
        // Reinforce:      [],
        // Impact:         [],
    },
    connect: {
        connect:    [],
        disconnect: [],
        reset:      [],
    }
}

export function onEvent(event,callback){
    callback.id = id(event,callback);
    if(!callbacks.event[event]){
        console.log("NO EVENT:",event);
        return;
    }
    callbacks.event[event].push(callback);
    return callback.id;
}
export function offEvent(event,id){
    for(let i = callbacks.event[event].length - 1; i >= 0; i--){
        if(callbacks.event[event][i].id === id){
            callbacks.event[event].splice(i,1);
        }
    }
}
function Event(event,params,eventObj){
    if(!params) params = [];

    for(let i = 0; i < callbacks.event[event].length; i++){
        callbacks.event[event][i](params,eventObj);
    }
}

async function _read(_contract,func,params){
    let args = [];
    if(params) {
        args = [...params]
    }
    return await contract[_contract][func](...args);
}
function parse_(event,callThrough){
    _events[event] = callThrough;
}
function parse_event(event){
    if(typeof event === "undefined") {
        console.log('undefined event');
        return;
    }

    const index = event.logIndex+event.transactionHash.substr(1,12);
    if(parsed_events[index]) {
        return;
    }
    parsed_events[index] = true;

    if(_events[event.event]){
        _events[event.event](event.args,event);
    }

    Event(event.event,event.args,event);

}

export const Contract = {

};
const ContractEvents = {}

for(let contractName in contractAbi){
    const C = {};
    // Contract[contractName] = {
    //
    // };
    const events = [];
    contractAbi[contractName].map(definition => {
        const D = parseDefinition(definition);
        switch (D.type){
            case "event":
                events.push(D.name);
                callbacks.event[D.name] = [];
                break;
            case "function":
                let func;
                if(D.mutability === "read"){
                    func = async (...funcArgs) => {
                        let args = [];
                        if(funcArgs.length <= D.params.length){
                            for(let i = 0; i < funcArgs.length; i++){
                                args.push(funcArgs[i]);
                            }
                        }else{
                            for(let i = 0; i < D.params.length; i++){
                                args.push(funcArgs[i]);
                            }
                        }
                        return await _read(contractName,D.name,args);
                    }
                    C[D.name] = func;
                }
                break;
        }

    });
    Contract[contractName] = C;
    ContractEvents[contractName] = events;
}


export function onConnect(transition,callback){
    callback.id = id(callback);;
    callbacks.connect[transition].push(callback);
    return callback.id;
}
export function offConnect(transition,id){
    for(let i = callbacks.connect[transition].length - 1; i >= 0; i--){
        if(callbacks.connect[transition][i].id === id){
            callbacks.connect[transition].splice(i,1);
        }
    }
}
async function doConnectCallbacks(transition){
    for(let i = 0; i < callbacks.connect[transition].length; i++){
        await callbacks.connect[transition][i]();
    }
}




export async function connect(){
    state.set('loading');

    console.log("CONNECT ALCHEMY");
    console.log("NETWORK:",getNetworkName());

    for(let c in contractAddress[getNetworkName()]) {
        contract[c] = new ethers.Contract(contractAddress[getNetworkName()][c], contractAbi[c], provider_alchemy);
    }

    for(let contractName in ContractEvents){
        await registerEvents(contractName,ContractEvents[contractName]);
    }

    await doConnectCallbacks("connect");

    state.set('loaded');
}


function registerEvents(contractName,event_names){
    for(let e = 0; e < event_names.length; e++){
        const event_name = event_names[e];
        contract[contractName].on( event_name , (a0,a1,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20)=>{
            let event;
            //default arguments variable isnt workin for some reason. this will do
            let args = [a0,a1,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20];

            for(let a = 0; a < args.length; a++) {
                if (typeof args[a] === "object"
                    && args[a].event === event_name) {
                    event = args[a];
                    break;
                }
            }
            if(!event) return;
            parse_event(event);
        } );
    }
}

async function unlockCallback(){
    // console.error("contract: unlock callback")
     await connect();

    // await doConnectCallbacks("connect");
}


async function lockCallback(){
    state.set("initial");
    await reset()

    await doConnectCallbacks("disconnect");
}

async function reset(){
    console.log("injected reset")

    stateId = Date.now();
    for(let property in _data){
        const type = _data[property].type;
        delete _data[property];
        _data[property] = Loadable(type);
    }
    data.set(_data);

    await doConnectCallbacks("reset");


}


let initialised = false;
export async function init() {
    if(initialised) return;
    initialised = true;

    // if(isReady){
    //     unlockCallback();
    // }else{
    //     onReady(unlockCallback);
    // }


    onUnlocked(unlockCallback);
    onLocked(lockCallback);


}




init();