import { ethers } from "ethers";
import { get, writable } from 'svelte/store';

import {getProvider, killProvider} from "./provider";

import {target_network} from "./targetNetwork";

let localLock = localStorage.getItem("unlocked");
let storedUnlock = !(!localLock || localLock === "false");

let walletChangeCallbacks = [];
export function onWalletChange(callback){
    walletChangeCallbacks.push(callback);
}
async function walletChanged(){
    for(let i = 0; i < walletChangeCallbacks.length; i++){
        await walletChangeCallbacks[i](get(correct_network));
    }
}
export const target_mainnet = target_network.chainId === 1;


export let unlocked =  writable(false);
export let address = writable(null);
export let ENS = writable(null);

export let provider = null;
export let signer = null;
export let correct_network = writable(true);
export let wallet_injected = writable(true);

async function check_network(provider){
    // console.error('check network')
    let network = await provider.getNetwork();
    // console.error(">>>",network)

    correct_network.set(network.chainId === target_network.chainId);
}

async function recheck_wallet(){
    if(!provider) return;
    // console.error("recheck wallet");
    await check_network(provider);

    let _signer = provider.getSigner();
    let _address = await _signer.getAddress();


    if(_address !== get(address)){
        console.log("address changed");

        address.set(_address);
        let _ens = null;
        try{
            _ens = await provider.lookupAddress( _address );
        }catch(e){
            console.log("No ens");
        }
        ENS.set(_ens);


        await walletChanged();
    }

    setTimeout(recheck_wallet,1000);
}


export let unlock_requested = writable(false);
let unlock_timeout = false;
let unlock_prompt = false;
function request_unlock(){
    unlock_timeout = true;
    unlock_prompt = true;
    unlock_requested.set(true);
    setTimeout(()=>{
        unlock_timeout = false;
        clear_unlock();
    },5000);
}
function clear_unlock(){
    unlock_requested.set(unlock_timeout || unlock_prompt);
}


export function check_unlock(){
    // setTimeout(async ()=>{
    //
    //         try{
    //             const _provider = await new ethers.providers.Web3Provider(window.ethereum,"any");
    //             const _signer = _provider.getSigner();
    //             const connected_address = await _signer.getAddress();
    //             console.log("Wallet connected");
    //             do_unlock();
    //         }catch(e){
    //             check_unlock();
    //         }
    //     },1000
    // );
}


async function do_unlock(){

    injectedCallback();

    unlocked.set(true);
    localStorage.setItem("unlocked",true);

    let modalProvider;
    try{
        modalProvider = await getProvider();

    }catch(e){
        console.log(e);
    }

    // console.log("provider:");
    // console.log(modalProvider);

    const _provider = await new ethers.providers.Web3Provider(modalProvider,"any");

    // const _provider = await new ethers.providers.Web3Provider(window.ethereum,"any");



    provider = _provider;


    // console.log(provider);
    // console.error("))))))")

    const _signer = _provider.getSigner();
    signer = _signer;

    await check_network(provider);

    // console.log(">",1);

    provider.on("network", async (newNetwork, oldNetwork) => {
        console.log("change network");

        await check_network(provider);
        await walletChanged();
    });
    // provider.on()

    // console.log(">",2);

    address.set(await _signer.getAddress());


    unlockCallback();

    // console.log(">",3);

    recheck_wallet();
}

export async function unlock(){
    try {
        do_unlock();
        // request_unlock();
        // await window.ethereum.request({
        //     method: 'eth_requestAccounts'
        // });

    } catch (e) {
        console.log('Wallet request failed');
        console.log(e);
        if(e.code !== 4001) alert(e.message);
    }
    unlock_prompt = false;
    clear_unlock();
    check_unlock();
}


export async function lock(){

    console.log('lock');

    localStorage.setItem("unlocked",false);

    if(provider.disconnect){
        await provider.disconnect();
    }
    if(signer.disconnect){
        await signer.disconnect();
    }

    if(provider.close){
        await provider.close();
    }
    if(signer.close){
        await signer.close();
    }



    unlocked.set(false);
    address.set(null);
    ENS.set(null);

    provider = null;
    signer = null;
    correct_network.set(true);

    await killProvider();


    lockCallback();
}







export default async function init() {
    // if(typeof window.ethereum === "undefined"){
    //     wallet_injected.set(false);
    //     console.log('not injected!');
    //     return;
    // }
    // console.log("injected: init succeeded");
    // window.ethereum.autoRefreshOnNetworkChange = false;

    if(storedUnlock){
        unlock();
    }
}


let unlockCallback = ()=>{
    for(let u = 0; u < unlockCallbacks.length; u++){
        unlockCallbacks[u]();
    }
};
let unlockCallbacks = [];
export function onUnlocked(callback){
    unlockCallbacks.push(callback);
}

let lockCallback = ()=>{
    for(let l = 0; l < lockCallbacks.length; l++){
        lockCallbacks[l]();
    }
};
let lockCallbacks = [];
export function onLocked(callback){
    lockCallbacks.push(callback);
}




let injectedCallback = ()=>{
    for(let u = 0; u < injectedCallbacks.length; u++){
        injectedCallbacks[u]();
    }
};
let injectedCallbacks = [];
export function onInjected(callback){
    injectedCallbacks.push(callback);
}

init();
