export let contractData = {

    // Any connection (Alchemy or Wallet)

    roninSupply:            ["bigInt"],     // Number of Ronin NFTs that exist
    roninUnclaimed:         ["bigInt"],     // Number of reserved and unclaimed Ronins
    roninPaused:            ["boolean"],    // Is Ronin contract paused

    kitsuneSupply:          ["bigInt"],     // Number of Kitsune NFTs that exist
    kitsuneStarted:         ["boolean"],    // Has Kitsune claim period started

    ronins:                 ["array"],      // Array of all existing Ronin tokenIDs
    kitsunes:               ["array"],      // Array of all existing Kitsune tokenIDs
    unclaimedKitsunes:      ["array"],      // Array of all Ronin tokenIDs that have not been used to claim Kitsunes

    // Wallet connection only

    myRoninCount:           ["bigInt"],     // Number of Ronin NFTs that user owns
    myRoninReserveCount:    ["bigInt"],     // Number of Ronin NFTs that user has reserved but not claimed
    myKitsuneCount:         ["bigInt"],     // Number of Kitsune NFTs that user owns

    myRonins:               ["array"],      // Array of tokenIDs of all Ronins that user owns
    myKitsunes:             ["array"],      // Array of tokenIDs of all Kitsunes that user owns
}